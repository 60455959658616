import React from 'react';
type Props = {
    logoPath: string,
    logoLink: string;
};

export const AppFooter = (props: Props) => {

    return (
        <div className="layout-footer">
            <span className="footer-text" style={{ 'marginRight': '5px' }}></span>
            <a href={props.logoLink} target="_blank"><img src={props.logoPath} alt="Logo" width="80" /></a>
            <span className="footer-text" style={{ 'marginLeft': '5px' }}>&copy; 2024</span>            
        </div>
    );
}
