import React, { Component } from 'react';
import Moment from 'react-moment';
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';
import { confirmDialog } from 'primereact/confirmdialog';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import FileSaver from 'file-saver';
import { Calendar } from 'primereact/calendar';
export class BaseComponent extends Component {
    
    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }
    alertError(ex) {
        var mes = <ScrollPanel style={{ width: '100%', height: '60px' }}>{ex}</ScrollPanel>;
        confirmDialog({
            message: mes,
            header: 'Avertizare',
            acceptLabel: "OK",
            acceptClassName: "p-button-danger",
            rejectLabel: " ",
            style: { width: '400px' }
        });
    }
    onInputChange(evt, name, name2, e) {
         
        if (evt instanceof Date) {
            evt= new Date(
                evt.getFullYear(),
                evt.getMonth(),
                evt.getDate(),3
            );           
        }
        this.setProperty(name, evt);
        if (name2 != null)
            this.setProperty(name2, e.originalEvent.currentTarget.innerText);
        if (this.state.entity != null) {
            this.setState({ entity: this.state.entity });
        }
        else if (this.state.obj != null) {
            this.setState({ obj: this.state.obj });
        }
        
    }
    setLoading = (show) => {

        this.setState({ loading: show });
   

    }
   static getProp(obj, column) {
        var nameSp = (""+column).split('.');
        for (var i = 0; i < nameSp.length; i++) {
            obj = obj[nameSp[i]];
            if (obj == null) {
                break;
            }
        }
        return obj;
    }
    createData(dt) {
        var list = [];
        for (var i = 0; i < dt.current.props.value.length; i++) {
            var obj = {};
            var row = dt.current.props.value[i];
            for (var j = 0; j < dt.current.props.children.length; j++) {
                var prop = dt.current.props.children[j].props;
                if (prop.field != null) {
                    var split = prop.field.split('.');
                    var val = null;
                    for (var k = 0; k < split.length; k++) {
                        val = val == null ? row[split[k]] : val[split[k]];
                    }
                    obj[prop.header] = val;
                }
            }
            list.push(obj);
        }         
        return list;
    }
    exportExcel(dt,fileName) {
        var data = this.createData(dt);
        import("xlsx").then(xlsx => {
            const workSheet = xlsx.utils.json_to_sheet(data);
            const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workBook, {
                bookType: "xlsx",
                type: "array"
            });
            this.saveAsExcelFile(excelBuffer, fileName);
        });
    }

    saveAsExcelFile(buffer, fileName) {
         
        var EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
        var EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer],
            {
                type: EXCEL_TYPE
            });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);

    }

    dateBodyTemplate(rowData, column) {

        var r = BaseComponent. getProp(rowData, column.field);
        return (
            <React.Fragment>
                { r != null &&
                    <Moment format="DD.MM.YYYY">{r}</Moment>
                }
            </React.Fragment>
        );
    }
   
    dateTimeBodyTemplate(rowData, column) {

        var r = BaseComponent.getProp(rowData, column.field);
        return (
            <React.Fragment>
                {r != null &&
                    <Moment format="DD.MM.YYYY HH:mm:ss">{r}</Moment>
                }
            </React.Fragment>
        );
    }

    decimalBodyTemplate(rowData, column) {

        var r = BaseComponent.getProp(rowData, column.field);
        return (
            <React.Fragment>
                {r != null &&
                    (Math.round(r * 100) / 100).toFixed(2)
                }
            </React.Fragment>
        );
    }

    monthBodyTemplate(rowData, column) {

        var r = BaseComponent.getProp(rowData, column.field);
        return (
            <React.Fragment>
                {r != null &&
                    <Moment format="MM.YYYY">{r}</Moment>
                }
            </React.Fragment>
        );
    }
    checkBodyTemplate(rowData, column) {
        var r = BaseComponent.getProp(rowData, column.field);
        return (
            <React.Fragment>
                <center>
                    <Checkbox checked={r != null ? r : false}></Checkbox>
                </center>
            </React.Fragment>
        );
    }
    setProperty(name, value) {
        var nameSp = name.split('.');
        var i = 0;
        var ent = this.getEntity();
        for (i = 0; i < nameSp.length - 1; i++) {
            ent = ent[nameSp[i]];
        }
        ent[nameSp[i]] = value;
    }

    getEntity() {
        return this.state.entity != null ? this.state.entity : (this.state.obj != null ? this.state.obj: this.state);
    }

    onGlobalFilterChange(e, grid) {

        const value = e.target.value;
        let _filters = { ...this.state[grid] };
        
        _filters['global'].value = value;
        this.state.globalFilterValue[grid] = value;
        this.setState({ [grid]: _filters, globalFilterValue: this.state.globalFilterValue });

    };
    dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd.mm.yy" placeholder="dd.mm.yyyy" mask="99.99.9999" />;
    };

    renderHeader(grid) {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={this.state.globalFilterValue[grid]} onChange={(e) => { this.onGlobalFilterChange(e, grid); }} placeholder="Cautare" />
                </span>
            </div>
        );
    };
  
    render() {
        return (
            <div>
            </div>
        );
    }
}

         