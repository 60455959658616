import React, { Component } from 'react';

export class HomeAdmin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}
export default HomeAdmin;