import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import authService from './api-authorization/AuthorizeService';

export class HomeCusator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            procentRealizare: ""
        };
    }

    componentDidMount() {
        this.loadProcentRealizare();
    }

    async loadProcentRealizare() {
        this.setLoading(true);

        var resultProcentrealizare = [];
        var procent = "";
        var today = new Date();
        var firstDay = new Date(+today.getFullYear(), today.getMonth(), +1);

        this.setState({ submitted: true, activeIndex: 0 });
        try
        {
            resultProcentrealizare = await authService.fetchAuth("Report/RunReport", { report: { storedProcedureName: "spGetReportTimpLucruLuna" }, parameters: { "StartDate": firstDay, "EndDate": today } });
            procent = resultProcentrealizare != null && resultProcentrealizare.length > 0 ? "" + resultProcentrealizare[0].ProcentNorma + "%" : "0.00%";
        }
        catch { }

        this.setState({ procentRealizare: procent, submitted: false });

        this.setLoading(false);
    }

    setLoading = (show) => {
        this.setState({ loading: show });
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading} >
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <div className="p-col-12 p-md-4">
                            <div className="box p-shadow-5 pt-2 pb-2">
                                <div className="p-grid p-col-12 pl-4">
                                    <div className="p-col-11">
                                        <p className="pr-1" style={{ 'fontSize': '24px', 'color': '#008CBA', 'font-weight': 'bold' }}>BINE ATI VENIT!</p>
                                    </div>
                                </div>
                                <div className="p-grid p-col-12 pl-4 pt-4 pb-2">
                                    <div className="p-col-12" style={{ 'border': '2px solid #008CBA', 'border-radius': '19px', 'textAlign': 'center' }}>
                                        <i className="pi pi-fw pi-user-edit" style={{ 'fontSize': '26px', 'color': '#008CBA' }}></i>
                                        <Link to='secui/UserInfo'><p className="pr-2 pl-1" style={{ 'display': 'inline-block', 'fontSize': '24px', 'color': '#008CBA' }}>Date utilizator</p></Link>
                                    </div>
                                </div>
                                <div className="p-grid p-col-12 pl-4 pt-4 pb-2">
                                    <div className="p-col-12" style={{ 'border': '2px solid #008CBA', 'border-radius': '19px', 'textAlign': 'center' }}>
                                        <i className="pi pi-fw pi-calendar-plus" style={{ 'fontSize': '26px', 'color': '#008CBA' }}></i>
                                        <Link to='secui/DayActivity'><p className="pr-2 pl-1" style={{ 'display': 'inline-block', 'fontSize': '24px', 'color': '#008CBA' }}>Activitate zilnica</p></Link>
                                    </div>
                                </div>
                                <div className="p-grid p-col-12 pl-4 pt-4 pb-2">
                                    <div className="p-col-12" style={{ 'border': '2px solid #008CBA', 'border-radius': '19px', 'textAlign': 'center' }}>
                                        <i className="pi pi-fw pi-calendar-minus" style={{ 'fontSize': '26px', 'font-weight': 'bold', 'color': '#008CBA' }}></i>
                                        <Link to='secui/StornoActivity'><p className="pr-2 pl-1" style={{ 'display': 'inline-block', 'fontSize': '24px', 'color': '#008CBA' }}>Storno activitate</p></Link>
                                    </div>
                                </div>
                                <div className="p-grid p-col-12 pl-4 pt-4 pb-2">
                                    <div className="p-col-12" style={{ 'border': '2px solid #008CBA', 'border-radius': '19px', 'textAlign': 'center' }}>
                                        <i className="pi pi-fw pi-chart-line" style={{ 'fontSize': '26px', 'color': '#008CBA' }}></i>
                                        <Link to='secui/HistoryActivity'><p className="pr-2 pl-1" style={{ 'display': 'inline-block', 'fontSize': '24px', 'color': '#008CBA' }}>Istoric activitate</p></Link>
                                    </div>
                                </div>
                                {/*<div className="p-grid p-col-12 pl-4 pt-1 pb-3">*/}
                                {/*    <div className="p-col-12" style={{ 'fontSize': '550%', 'color': '#33CC33', 'textAlign': 'center' }}>*/}
                                {/*        <b>{this.state.procentRealizare}</b>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi >
        );
    }
}
export default HomeCusator;